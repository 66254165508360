.multi-select {
    position: relative;
    font-family: Arial, sans-serif;
}

.selected-options {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
}



.selected-options input {
    border: none;
    outline: none;
    flex: 1;
    margin-left: 5px;
}

.search-input {
    width: 100%;
    border: none;
    outline: none;
}

.options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 5px 5px;
    background-color: #fff;
    z-index: 999;
}

.option {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.option:hover {
    background-color: #f0f0f0;
}

.selected {
    background-color: #3498db;
    color: #fff;
}

.selected-option {
    position: relative;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 5px;
    background-color: #f0f0f0;
    border-radius: 3px;
}

.remove-option {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 13px;
    height: 13px;
    background-color: red;
    color: white;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
}