.menu-template {
  border-right: 2px solid #e9ecef;
  padding-right: 15px;
  padding-bottom: 5px;
  height: 97%;
}

.scrollable-list {
  max-height: 89%;
  overflow-y: auto;
}

.template-content {
  padding-top: 15px;
  width: 99%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.panel-parameters {
  display: table;
  width: 100%;
}


.panel-header {
  margin: 20px;
  display: flex;
  justify-content: space-between;
}

.switch-container {
  align-items: center;
}

.switch-title {
  margin-right: 5px;
}

.row {
  display: table-row;
}

.cell {
  display: table-cell;
  padding: 5px;
  text-align: center;
}

.panel-table {
  margin: 5px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}


.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}


.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 20px;
}


.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:checked+.slider:before {
  transform: translateX(20px);
}


.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

.flex{
  display: flex;
}

.panel {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: #007bff;
  color: white;
  border-color: #ddd;
}

.panel-body {
  padding: 15px;
}

.small-image {
  max-width: 800px; 
  max-height: 250px; 
  display: block;
  margin-left: auto;
  margin-right: auto;
}
